import React from 'react';
import SVG from 'react-inlinesvg';
import { StaticImage } from 'gatsby-plugin-image';
import { motion, AnimatePresence } from 'framer-motion';
import { Video } from '@bhvr/web-design-system';

import Heading from '@base/parts/heading';
import Markdown from '@base/parts/markdown';
import Button from '@base/parts/button';

import arrow from '@assets/img/icons/arrow.svg';
import Logo from '@elements/logo';

function HeroBlock({ data, pageContext, globalData }) {
  return (
    <AnimatePresence>
      <section className='hero-section'>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5, delay: 0.5 }}
          className='hero-content'
        >
          <Logo globalData={globalData} />
          <Heading
            text={data.title}
            tag='h1'
            className='mb-20 text-md lg:text-lg'
          />
          {data?.buttons?.map((button, i) => (
            <Button
              link={button.link}
              key={`heroBtn-${i}`}
              trigger={button?.trigger}
              pageContext={pageContext}
              className={button.className}
              eventAction={button?.eventAction}
              eventPage={pageContext.pageDataId}
              eventSection='home keyart'
              gsEvent={button?.gsEvent}
              trackable
              ratingTimeBuffer={
                button.ratingTimeBuffer ? button.ratingTimeBuffer : 1000
              }
            >
              <span>{button?.text}</span>
            </Button>
          ))}
        </motion.div>
        {data.text && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5, delay: 0.75 }}
            className='hero-more'
          >
            <Markdown>{data.text}</Markdown>
            <SVG
              cacheRequests
              src={arrow}
              preProcessor={(code) => code.replace(/fill="#6f777c"/g, 'fill="currentColor"')}
              className='fill-white'
            />
          </motion.div>
        )}
        <StaticImage
          src='../../assets/img/blight-survival-keyart.jpg'
          alt='Blight: Survival Keyart'
          placeholder='blurred'
          loading='lazy'
          className='hero-keyart gatsby-absolute gatsby-hidden-desktop'
        />
        <Video
          bgLoop
          autoplay
          loading='lazy'
          loop
          muted
          player='self'
          className='hero-video'
          source={data.keyart.publicURL}
          poster={data.keyartPoster.publicURL}
        />
      </section>
    </AnimatePresence>
  );
}

export default HeroBlock;
