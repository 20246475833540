/*
  PAGE TEMPLATE
  A template to display fully editable pages
*/

import React, { useEffect, useContext } from 'react';
import { graphql } from 'gatsby';

import PostContextProvider from '@base/utils/contexts/posts-context';
import PageLayout from '@base/layout/page-layout';
import Blocks from '@base/layout/blocks';
import triggerPageView from '@base/utils/helpers/tracking/trigger-page-view';
import { GeolocalizationContext } from '@base/utils/contexts/geolocalization-context';

import { getGeneralData } from '@api/general-data';

function DynamicPage({ data, pageContext }) {
  const { legalAge } = useContext(GeolocalizationContext);
  const [isLegalAge, setLegalAge] = legalAge;

  const generalData = getGeneralData(pageContext, data);
  const minifiedPageContext = generalData.pageContext;
  const { globalData } = generalData;
  const { modalData } = generalData;
  const { title, parentPage, disablePageBreadcrumbs } = data.pageData;
  const { contentSections } = data.blocksData;

  // Track Page Views
  useEffect(() => {
    setLegalAge(true);
    const eventPage = data.pageData.pageDataId || data.pageData.title?.replace(/\s+/g, '_')?.toLowerCase();
    triggerPageView(pageContext, eventPage);
  }, []);

  return (
    <PageLayout
      pageContext={minifiedPageContext}
      modalData={modalData}
      breadcrumbs={{
        enableGlobalBreadcrumbs: true,
        disablePageBreadcrumbs,
        postTitle: title,
        postParent: [parentPage],
        strings: { back: globalData.stringsActions.back, home: 'Home' },
      }}
      className={`content-${pageContext.locale}`}
    >
      <PostContextProvider>
        <Blocks
          pageContext={minifiedPageContext}
          globalData={globalData}
          blocks={contentSections}
        />
      </PostContextProvider>
    </PageLayout>
  );
}
export default DynamicPage;

export const query = graphql`
  query DynamicPageQuery($id: String!) {
    pageData: pagesJson(id: { eq: $id }) {
      dataId
      pageDataId
      title
      slug
      locale
      disablePageBreadcrumbs
      disableNewsletterSignup
      disableBuyGameSection
      disableAgeGate
      parentPage {
        slug
        title
      }
      metadata {
        metaTitle
        metaDescription
        canonicalURL
        disablePageIndexing
        shareImage {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
          publicURL
        }
      }
      localizations {
        dataId
        locale
        slug
      }
    }
    blocksData: pagesJson(id: { eq: $id }) {
      contentSections {
        ...Blocks
      }
    }
  }
`;
export { Head } from '@base/layout/head';
